import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, HashRouter } from "react-router-dom";
import { store } from './store/store';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { DynamicDataProvider } from './context/DynamicDataProvider';

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

let firstLoad = true;

function handleMessage(event:any){
  console.log("received 1index.js : ", event.data,firstLoad );
  if (firstLoad) {
    setTimeout(() => {
      console.log("post message rec ", event.data );
      window.postMessage(event.data,"*")
     }, 1000);
  }
  firstLoad=false;
}

if(firstLoad){
  //add it
  window.addEventListener('message',handleMessage);
  //remove it
  setTimeout(() => {
    window.removeEventListener('message',handleMessage);
  }, 500);
}

root.render(
  <React.StrictMode>
    <Provider store={store}>
    <HashRouter basename="/">
    <DynamicDataProvider>

    <QueryClientProvider client={queryClient}>
      <App />
      {/* <ReactQueryDevtools initialIsOpen={true} />    */}
    </QueryClientProvider>
    </DynamicDataProvider>
    </HashRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
